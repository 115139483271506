<template>
  <div class="quote-manage">
    <div class="header-search">
      <span class="search-item">
        <span class="search-name">计划编号:</span>
        <comInput class="input-width"  v-model="purchaseOrderNumberData.value" :data='purchaseOrderNumberData'></comInput>
      </span>
      <span class="search-item">
        <span class="search-name">状态:</span>
        <comSelect class="input-width select-status" v-model="statusData.value" :data='statusData'></comSelect>
      </span>
      <span class="search-item">
      <span class="search-name">报价日期:</span>
        <el-date-picker class="date"
          v-model="createTimeData.value"
          type="daterange"
          align="right"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd">
      </el-date-picker>
      </span>
      <span class="operate-btn">
        <fontButton @click="search">查询</fontButton>
        <fontButton @click="reset">重置</fontButton>
      </span>
    </div>
    <div class="statistic">
      <statisticBar :statisticData="statisticData" />
    </div>
    <div class="table-main">
      <ContentTable :tableData='tableData' tableType='table' @page-change='pageChange'>
      </ContentTable>
    </div>
  </div>
</template>
<script>
import {tableData_quote} from "../../common/data"
import basePage from "../../common/basePage.vue"
import {ajax_get_quoteList} from "../../../../api/index.js"
export default {
  name: 'quoteManage',
  mixins: [basePage],
  data () {
    return {
      purchaseOrderNumberData:{
        placeholder: "请输入采购计划名称或编号",
        value: ""
      },
      statusData:{
        placeholder: "请选择状态",
        value: "",
        options:[
          {label:"待公布", value:1},
          {label:"已成交", value:2},
          {label:"未成交", value:3},
        ]
      },
      createTimeData:{
        value:""
      },
      statisticData:[
        {name:"全部报价", value:"123", id:"allCount"},
        {name:"待公布", value:"2",id:"auditCount"},
        {name:"已成交", value:"2",id:"completedCount"},
        {name:"未成交", value:"2",id:"failureCount"}
      ],
    }
  },
  created(){
    this.pageInit()
  },
  methods:{
     pageInit(){
      this.ajaxListMethods = ajax_get_quoteList;
      this.tableData = this.$deepClone(tableData_quote)
      this.generateDetailArr();
      this.generateFormArr("quote")
      this.ajax_list_get(true)
      this.ajax_get_statistic()
    },
    async ajax_get_statistic(){
      let res = await this.ajax_get_statisticData(5)
      console.log(res,"statistc")
      this.statisticData.forEach(v=>{
        v.value = res[v.id]
      })
    },
    search(){
      this.ajaxGetData.purchaseOrderNumber = this.purchaseOrderNumberData.value;
      this.ajaxGetData.status = this.statusData.value
      if(this.createTimeData.value && this.createTimeData.value.length){
        this.ajaxGetData.startTime = this.createTimeData.value[0] + " 00:00:00";
        this.ajaxGetData.endTime = this.createTimeData.value[1] + " 23:59:59";
      }else{
        this.ajaxGetData.startTime ="";
        this.ajaxGetData.endTime = '';
      }
      this.ajax_list_get(true)
    },
    handerTableData(list){
      let resList = [];
      list.forEach((element,index)=>{
        element.statusStr = (this.statusData.options.find(v=>v.value == element.status) || {}).label || "未知"
        element.index = (this.pageData.pageNo - 1) * this.pageData.pageSize + index +1
        resList.push(element)
      })
      return resList;
    },
    reset(){
      this.purchaseOrderNumberData.value = "";
      this.statusData.value = "";
      this.createTimeData.value = "";
    }
  }
}
</script>
<style lang="less" scoped>
.quote-manage {
  height: 100%;
  width: 100%;
  font-size: 14px;
  .header-search{
    width: 100%;
    height: 40px;
    text-align: left;
    margin-bottom: 15px;
    display: flex;
    .search-item{
      margin-right: 15px;
      .date{
         width: 210px;
      }
      .search-name{
        margin-right: 10px;
      }
      .input-width{
        width: 130px;
      }
      .select-status{
        width: 70px;
      }
    }
    .operate-btn{

    }
  }
  .table-main{

  }
}
</style>
